<script>
export default {
  name: "LandingPageMixin",

  // input: path: '/map/:layer_or_landing_page',
  // input: path: '/map/:country/:layer',

  // support 1: /myname: landingpage containing country and layer
  // support 2: /gemeente: translated layer name with default country
  // support 3: /municipality: layer name with default country
  // Support 4: /NL/gemeente/: country code and translated layer name
  // Support 5: /NL/municipality: country code and layer name

  watch: {
    '$store.state.config.country_and_layers': {
      deep: true,
      handler(){
        this.supportLandingArguments();
      }

    }
  },

  methods: {
    supportLandingArguments() {

      if (this.$route.params.layer_or_landing_page) {
        this.deal_with_layer_or_landing_page(this.$route.params.layer_or_landing_page)
        return
      }

      if (this.$route.params.country && this.$route.params.layer) {
        // basically the same logic as the default country.
        this.navigate_to_translated_layer(this.$route.params.layer, this.$route.params.country)
      }
    },


    deal_with_layer_or_landing_page(page) {
      if (this.is_landing_page(page)) {
        this.navigate_to_landing_page(page)
        return
      }

      // a layer can be translated or direct. Assume it's translated first.
      this.navigate_to_translated_layer(page)
    },


    navigate_to_landing_page(page) {
      this.setCountryLayer(
          this.$store.state.config.app.landing_pages[page].country,
          this.$store.state.config.app.landing_pages[page].layer
        )
    },

    navigate_to_translated_layer(page, country){
      // move to the first match. This might be the wrong one if there are multiple the same layers in different
      // countries... (should this just be the default country?)
      let options = this.translated_layer_names()

      let current_country = this.$route.params.country

      if (country) {
        current_country = country
      }

      page = page.toLowerCase()
      let found = false;

      // exact match on translated layer: the translated layer + default country match:
      options.forEach(option => {
        if (option['translated'] === page && option['country code'] === current_country){
          this.setCountryLayer(current_country, option['layer'])
          found = true;
        }
      })
      if (found)
        return found

      // exact match on non-translated layer, with the default country
      options.forEach(option => {
        if (option['layer'] === page && option['country code'] === current_country){
          this.setCountryLayer(current_country, option['layer'])
          found = true;
        }
      })
      if (found)
        return found

      // the word might be something that is not in the default country. In that case select the first match
      // of the translated layer.
      options.forEach(option => {
        if (option['translated'] === page){
          this.setCountryLayer(current_country, option['layer'])
          found = true;
        }
      })
      if (found)
        return found

      // or only the layer in another country:
      options.forEach(option => {
        if (option['layer'] === page){
          this.setCountryLayer(current_country, option['layer'])
          found = true;
        }
      })

      return found
    },


    translated_layer_names(){
      /*
      Converts this to....
      * "country_and_layers": {
          "NL": {
            "code": "NL",
            "name": "Netherlands",
            "flag": "/static/flags/nl.gif",
            "layers": [
              "municipality",
              "healthcare",
              "province",
              "government"
            ]
          }

        [
        {'country code': 'nl', 'country name': 'netherlands', 'layer': 'municipality', 'layer translated': 'gemeente'}
        ]
      * */

      // get all layers, then translate all of them, make sure its lower case. Return that as a list of strings.
      let cl = this.$store.state.config.country_and_layers;
      let locales = this.$store.state.config.app.supported_locales;

      let countries = Object.keys(cl);
      let layers = []

      locales.forEach(locale => {
        countries.forEach(country => {
          cl[country].layers.forEach(layer => {
            layers.push({
              'country code': country.toLowerCase(),
              'country name': cl[country].name.toLowerCase(),
              'layer': layer.toLowerCase(),
              'translated': this.$i18n.t("layer.layer_"+layer, locale).toLowerCase()}
            )
          })
        })
      })

      // console.log(layers)

      return layers;
    },

    is_landing_page(value){
      if (this.$store.state.config.app.landing_pages === undefined)
        return false

      return this.$store.state.config.app.landing_pages[value] !== undefined
    },


    setCountryLayer(country, layer){
      this.$store.commit('change', {country: country, layer: layer});
    },

  }
}
</script>
